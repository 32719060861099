import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import MailChimpForm from "../components/MailchimpForm";
import styled from "styled-components";

const BlogPhoto = styled.section`
  padding-top: 150px;
  padding-bottom: 150px;
  background-size: cover;
  background-image: linear-gradient(114deg,#000f2fb5 0,#000f2fb5 100%),url(${props => props.image || ''});
  background-position: 50% 50%;
  text-align: center;
`;

const BlogTitle = styled.h1`
  color: #ffffff;
  text-transform: uppercase;
`

const PostInfo = styled.div`
  color: #ffffff;
`

export const BlogPostTemplate = ({
  author,
  categories,
  content,
  contentComponent,
  date,
  description,
  excerpt,
  featuredimage,
  helmet,
  slug,
  tags,
  timeToRead,
  title
}) => {
  const PostContent = contentComponent || Content
  let authorName;

  switch (author) {
    case "KV":
      authorName = "Kamil Vávra";
      break;
    case "JM":
      authorName = "Ján Masarik";
      break;
    case "MŽ":
      authorName = "Martin Žember";
      break;
    case "PS":
      authorName = "Petr Skyva";
      break;
    case "FH":
      authorName = "Filip Holec";
      break;
    default:
      authorName = "TunaSec";
  }


  return (
      <>
        <BlogPhoto image={featuredimage.publicURL}>
          <BlogTitle style={{marginBottom: '50px', display: 'inline'}} className="title is-size-2 has-text-weight-bold is-bold-light">
            {title}
          </BlogTitle>
          <PostInfo id="post_info">
            <small>Datum: {date} | {timeToRead} min. čtení | Autor: {authorName}</small>
            {/*<br/>
              <small>Categories:</small> |
              <small> Tags: {tags.map(tag => (<Link to={`/tags/${kebabCase(tag)}/`}>{tag} / </Link>))}</small>*/}
          </PostInfo>
        </BlogPhoto>
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1 blog-article">
            <PostContent content={content}/>
            <hr style={{border: '1px dashed'}} />
            <div className="column is-12 has-text-centered">
                <MailChimpForm/>
            </div>
            <div style={{marginBottom: '40px'}}/>
          </div>
        </div>
      </div>
    </section>
      </>
  )
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      headline: post.frontmatter.title,
      description: post.excerpt,
      image: 'https://tunasec.com'+post.frontmatter.featuredimage.publicURL,
      url: 'https://tunasec.com'+post.fields.slug,
      datePublished: post.frontmatter.date,
      dateModified: post.frontmatter.date,
      author: {
        '@type': 'Person',
        name: post.frontmatter.author,
      },
      inLanguage: 'cs_CZ',
      publisher: {
        '@type': 'Organization',
        url: 'https://tunasec.com/',
        name: 'TunaSec',
        logo: {
          '@type': 'ImageObject',
          'url': 'https://tunasec.com/img/tunalogo.webp',
        }
      },
      mainEntityOfPage: {
        '@type': 'WebPage',
      }
    },
  ];

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            {/* General tags */}
            <title>{post.frontmatter.title}</title>
            <meta name="description" content={post.excerpt} />
            <meta name="image" content={'https://tunasec.com'+post.frontmatter.featuredimage.publicURL} />
            <meta name="robots" content="index, follow" />
            <meta name="keywords" content={post.frontmatter.tags} />

            {/* Twitter Card tags */}
            <meta name="twitter:site" content="@tunasec_cz" />
            <meta name="twitter:creator" content="@tunasec_cz" />
            <meta name='twitter:title' content={post.frontmatter.title} />
            <meta name='twitter:description' content={post.excerpt} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:image' content={'https://tunasec.com'+post.frontmatter.featuredimage.publicURL} />

            {/* OpenGraph tags */} 
            <meta property='og:image' content={'https://tunasec.com'+post.frontmatter.featuredimage.publicURL} />
            <meta property='og:locale' content='cs_CZ' />
            <meta property='og:type' content='article' />
            <meta property='og:title' content={post.frontmatter.title} />
            <meta property='og:description' content={post.excerpt} />
            <meta property='og:url' content={'https://tunasec.com'+post.fields.slug} />
            <meta property='og:updated_time' content={post.frontmatter.date} />
            <meta property="og:site_name" content="TunaSec.cz" />

            {/* Schema.org tags */}
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>

          </Helmet>
        }
        author={post.frontmatter.author}
        date={post.frontmatter.date}
        categories={post.frontmatter.categories}
        excerpt={post.excerpt}
        featuredimage={post.frontmatter.featuredimage}
        slug={post.fields.slug}
        tags={post.frontmatter.tags}
        timeToRead={post.timeToRead}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      timeToRead
      fields {
        slug
      }
      frontmatter {
        author
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        categories
        featuredimage {
          publicURL
          name
        }
      }
    }
  }
`
